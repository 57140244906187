// This component is added alongside the extension of the Backstage user token expiration (via config) to the max of 24 hrs -
// with the intent of it being a temporary patch to remove the confusing and unhelpful 404/401 combination
// presently seen on expired backstage token and active Okta/SAML session, due to the incomplete nature of the
// SAML provider included in the Backstage framework. It should be noted that this is considered a patch of necessity,
// To be removed at such time as a complete session management scheme is in place for the SAML provider in use.

import React, {
  useLayoutEffect,
  // useState,
  ReactNode,
} from 'react';
import { decodeJwtPayload } from './utils';

interface SessionStorageCleanupProps {
  children: ReactNode;
}

const LocalStorageCleanup: React.FC<SessionStorageCleanupProps> = ({
  children,
}) => {
  const clearSession = () => localStorage.removeItem('samlSession');
  const clearProvider = () =>
    localStorage.removeItem('@backstage/core:SignInPage:provider');

  // const logout = () => {
  //   clearProvider();
  //   clearSession();
  //   window.location.href = ('/');
  // };

  useLayoutEffect(() => {
    try {
      const sessionData = localStorage.getItem('samlSession');

      if (sessionData) {
        // console.info('\n JSON.parse(sessionData) =>', JSON.parse(sessionData));
        const parsed = JSON.parse(sessionData);

        const token = parsed.backstageIdentity.token;
        // console.log(`token: \n`, token)

        const { exp } = decodeJwtPayload(token);

        if (!exp) {
          // eslint-disable-next-line no-console
          console.warn('Invalid or missing token exp value.');
          clearProvider();
          clearSession();
          window.location.href = '/';
        }
        // console.log(`issued: \n`, iat);
        // console.log(`expires: \n`, exp);
        const timestamp = Math.floor(Date.now() / 1000);
        // console.log(`local timestamp: \n`, timestamp);

        // 60 * 9.7; when testing locally, it can be advantageous to use ~ 9.7 minutes
        // here as seen above, and set backstage token age at 10 minutes
        // via the auth.backstageTokenExpiration config key.
        const tolerance = 60 * 60 * 2; // (should refresh if within 2 hrs of expiry.)

        // // decide if it's expired
        const shouldLogout = Boolean(exp - tolerance < timestamp);
        // console.log(`warning, they should logout: \n`, shouldLogout)

        // todo:: dump it, and refresh.
        if (shouldLogout) {
          // console.log(`Should Logout && clear local storage... doing so now.`);
          clearSession();
          clearProvider();
          window.location.href = '/';
        }
        // else {
        //   console.log(`exp of ${exp} minus tolerance of ${tolerance} is not less than current time of ${timestamp}: seconds til expired: ${timestamp - (exp - tolerance)}`)
        // }
        // } else {
        //   console.log('session data not present, Did you just load Home for the first time?.......');
      }
    } catch (error) {
      // todo:: deal with complexities of epoch time on user system compared with backend server time and userdata is WEIRD.
      // todo:: what happens if token isn't parseable? (bad json)
      clearSession();
      clearProvider();
      // eslint-disable-next-line no-console
      console.error('something went wrong parsing or evaluating session: \n');
      throw new Error(`${String(error)}`);
    }
  }, []);

  return <>{children}</>;
};

export default LocalStorageCleanup;
