import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class ProxyTechRadarApi implements TechRadarApi {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;

  constructor({
    discoveryApi,
    fetchApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const proxyBaseUri = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/tech-radar${id ?? ''}`;
    const techRadarUri = `${proxyBaseUri}/tech-radar.json`;

    const response = await this.fetchApi.fetch(techRadarUri);

    if (response.ok) {
      const data = await response.json();

      return {
        ...data,
        entries: data.entries.map(
          (entry: { timeline: { date: string | number | Date }[] }) => ({
            ...entry,
            timeline: entry.timeline.map(
              (timeline: { date: string | number | Date }) => ({
                ...timeline,
                date: new Date(timeline.date),
              }),
            ),
          }),
        ),
      };
    }

    return {
      quadrants: [],
      rings: [],
      entries: [],
    };
  }
}
