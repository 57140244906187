import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  identityApiRef,
  errorApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  ApiRef,
  ProfileInfoApi,
  SessionApi,
  BackstageIdentityApi,
  createApiRef,
} from '@backstage/core-plugin-api';

import { SamlAuth } from '@backstage/core-app-api';

import { ProxyTechRadarApi } from './lib/ProxyTechRadarApi';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { VisitsWebStorageApi, visitsApiRef } from '@backstage/plugin-home';

export const localSamlAuthApiRef: ApiRef<
  ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'internal.auth.saml',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  // localStorage data PageVisits implementation,
  // relies on WebStorage implementation of storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef,
    },
    factory: ({ identityApi, errorApi }) =>
      VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),

  // Proxied Tech Radar API
  createApiFactory({
    api: techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      new ProxyTechRadarApi({ discoveryApi, fetchApi }),
  }),
  // SAML auth
  createApiFactory({
    api: localSamlAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi }) =>
      SamlAuth.create({
        discoveryApi,
        provider: {
          id: 'saml',
          title: 'Custom saml provider',
          icon: () => null,
        },
      }),
  }),
];
