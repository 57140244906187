export const encodeJwtPayload = payloadObject => {
  // Convert the JSON payload to a string
  const payloadString = JSON.stringify(payloadObject);

  // Encode the string to Base64
  const base64 = window.btoa(payloadString);

  // Replace '+' with '-' and '/' with '_'
  const base64Url = base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, ''); // Remove '=' padding

  return base64Url;
};

export const decodeJwtPayload = token => {
  try {
    // decode payload
    const payload = token.split('.')[1];
    // console.log(`payload: \n`, payload)

    // reformat payload for correct atob decode
    let base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const padLength = (4 - (base64.length % 4)) % 4;
    base64 += '='.repeat(padLength);

    const decoded = window.atob(base64);
    // console.log(`decoded: \n`, decoded)
    const obj = JSON.parse(decoded);
    return obj;
  } catch (error) {
    throw new Error(`Unable to decode token as given: ${error}`);
  }
};
// todo:: test encode and decode utils specifically if needed
